export function isDef(v) {
    return v !== undefined && v !== null
}

/**
 * Remove an item from an array.
 */
export function remove(arr, value) {
    return arr.filter((item)=>{
        return item != value;
    });
}

export function isRegExp(v) {
    return _toString.call(v) === '[object RegExp]'
}

export function toTitle(str) {
    if (str.length > 0) {
        return str.charAt(0).toUpperCase() + str.slice(1)
    } else {
        return str
    }
}

export function isEmpty(v) {
    if (v === null || v === undefined || v === '')
        return true
    else
        return false
}

export function isEmail(v) {
    return /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+\.)+[a-zA-Z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}))$/.test(v);
}

export function isImageUrl(v){
    return /\.(gif|jpg|jpeg|png|GIF|JPEG|JPG|PNG)$/.test(v)
}

export function getMapMarkerIconUrl(index){
    if(index >= 0)
        return 'https://images.jidulvxing.com:448/map/markers/' + (index + 1) + '.png';
    else
        return 'https://images.jidulvxing.com:448/map/markers/0.png';
}

export function getImageUrl(image, isBigImg = false){
    let image_url = image?.image_url
    let original_image_url = image?.original_image_url

    if(!isEmpty(image_url))
        image_url = image_url.replace(/\\/g,'/')

    if(!isBigImg) {
        if (!isEmpty(image_url)) {
            image_url = image_url + '?w=500'
        }
        if (!isEmpty(original_image_url)){
            if(original_image_url.indexOf('agoda.net') > 0 && original_image_url.indexOf('s=1024x768') > 0)
                original_image_url = original_image_url.replace('s=1024x768', 's=512x384')
            if(original_image_url.indexOf('bstatic.com/xdata/images/hotel/840x460') > 0)
                original_image_url = original_image_url.replace('840x460', '420x230')
        }
    }

    return (!isEmpty(image_url) ? image_url : (!isEmpty(original_image_url) ? 'http:' + original_image_url : ''))
}

export function getFullImageUrl(imageUrl, isBigImg = false){
    if(isEmpty(imageUrl))
        return "";

    imageUrl = imageUrl.replace(/\\/g,'/')
    if(!isBigImg) {
        if (imageUrl.indexOf('agoda.net') > 0 && imageUrl.indexOf('s=1024x768') > 0)
            imageUrl = imageUrl.replace('s=1024x768', 's=512x384')
        else if (imageUrl.indexOf('bstatic.com/xdata/images/hotel/840x460') > 0)
            imageUrl = imageUrl.replace('840x460', '420x230')
        else
            imageUrl = imageUrl + '?w=500'
    }

    return imageUrl.replace('i.travelapi.com', 'api.epsapi.cn');
}

export function getEImageUrl(image, isBigImg){
    let href
    if (isBigImg === true) {
        href = image.links_json['1000px'].href
    } else if (isBigImg === false) {
        href = image.links_json['350px'].href
    } else {
        href = image.links_json['70px'].href
    }
    return href.replace('i.travelapi.com', 'api.epsapi.cn');
}

export function getBedTypeStr(roomBeds, t) {
    var html = "";
    for (var i = 0; i < roomBeds.length; i++) {
        var arr = [];
        for (var j = 0; j < roomBeds[i].length; j++) {
            arr.push(t('bedtype_unit.' + roomBeds[i][j].type, {n: roomBeds[i][j].num}));
        }
        html += "";
        if (i >= 1)
            html += " / ";
        html += arr.join("&");
        html += "";
    }
    return html;
}

export const NoCancelBeforeHoure = 87600
//是否可取消
export function isRefundable(cancelHour, checkIn, time){
    if(cancelHour === NoCancelBeforeHoure)
        return false

    let cancelTime = checkIn.add(1, 'day').add(-1, 'second').add(0 - cancelHour, 'hour')
    if(cancelTime.isAfter(time))
        return true
    else
        return false
}

//获取取消时间
export function getCancelDatetime(checkIn, cancelHour, format = 'YYYY-MM-DD HH:mm:ss'){
    return checkIn.add(1, 'day').add(-1, 'second').add(0 - cancelHour, 'hour').format(format)
}

export function getNights(checkin, checkout) {
    return checkout.diff(checkin, 'days')
}

//返回中文，如果中文为空，返回英文
export function getNameCn(cn, en) {
    return isEmpty(cn) ? en : cn
}

//返回中英文
export function getNameCnEn(cn, en) {
    if (isEmpty(cn))
        return en
    else
        return cn + ' ' + en
}

export function getEmptyValue(val, isZhCn) {
    if (isEmpty(val)) {
        return isZhCn ? '(无)' : '(None)'
    } else {
        return val
    }
}

// 获取唯一值
export function getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });
}

export function arrayEquals(array, array1) {
    // if the other array is a falsy value, return
    if (!array)
        return false;

    // compare lengths - can save a lot of time
    if (array1.length != array.length)
        return false;

    for (var i = 0, l = array1.length; i < l; i++) {
        // Check if we have nested arrays
        if (array1[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if (!array1[i].equals(array[i]))
                return false;
        } else if (array1[i] != array[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
}

export function objectEquals(object1, object2) {
    //For the first loop, we only check for types
    for (let propName in object1) {
        //Check for inherited methods and properties - like .equals itself
        //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/hasOwnProperty
        //Return false if the return value is different
        if (Object.prototype.hasOwnProperty.call(object1, propName) != Object.prototype.hasOwnProperty.call(object2, propName)) {
            return false;
        }
        //Check instance type
        else if (typeof object1[propName] != typeof object2[propName]) {
            //Different types => not equal
            return false;
        }
    }
    //Now a deeper check using other objects property names
    for(let propName in object2) {
        //We must check instances anyway, there may be a property that only exists in object2
        //I wonder, if remembering the checked values from the first loop would be faster or not
        if (Object.prototype.hasOwnProperty.call(object1, propName) != Object.prototype.hasOwnProperty.call(object2, propName)) {
            return false;
        }
        else if (typeof object1[propName] != typeof object2[propName]) {
            return false;
        }
        //If the property is inherited, do not check any more (it must be equa if both objects inherit it)
        if(!Object.prototype.hasOwnProperty.call(object1, propName))
            continue;

        //Now the detail check and recursion

        //object1 returns the script back to the array comparing
        /**REQUIRES Array.equals**/
        if (object1[propName] instanceof Array && object2[propName] instanceof Array) {
            // recurse into the nested arrays
            if (!object1[propName].equals(object2[propName]))
                return false;
        }
        else if (object1[propName] instanceof Object && object2[propName] instanceof Object) {
            // recurse into another objects
            //console.log("Recursing to compare ", object1[propName],"with",object2[propName], " both named \""+propName+"\"");
            if (!object1[propName].equals(object2[propName]))
                return false;
        }
        //Normal value comparison for strings and numbers
        else if(object1[propName] != object2[propName]) {
            return false;
        }
    }
    //If everything passed, let's say YES
    return true;
}

const _toString = Object.prototype.toString

export function isERoom(ratePlanId) {
    return ratePlanId.indexOf("y:") == 0
}

export function getECancelText(eitem, t, roomNum){
    if(eitem.nights) {
        return t('night_unit', {n: eitem.nights})
    }

    if(eitem.percent) {
        return eitem.percent
    }

    if(eitem.amount) {
        return eitem.currency + ' ' + (eitem.amount * roomNum)
    }
}

export function isEpsPartiallyRefundable(dayjs, epsRateInfo){
    if(!epsRateInfo || !epsRateInfo.cancel_penalties)
        return false

    //if(epsRateInfo.cancel_penalties.length > 1)
    //    return false

    let cancelPenaltie = epsRateInfo.cancel_penalties[0]
    if(dayjs().isAfter(dayjs(cancelPenaltie.start))) {
        /*if(cancelPenaltie.nights) {

        }
        if(cancelPenaltie.percent && cancelPenaltie.percent !== '100%') {
            return true
        }
        if(cancelPenaltie.amount) {
            return true
        }*/
        return true
    }

    return false
}