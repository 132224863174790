import Cookie from 'js-cookie'
import {
    ACCOUNT_LOGIN,
    ACCOUNT_SENDVERICODE,
    ACCOUNT_RESETPWD,
    ACCOUNT_EXISTLOGINNAME,
    ACCOUNT_SENDVERICODEFORREG,
    ACCOUNT_REGISTER, HOTEL_TOP_HOTELS
} from "@/services/api";

const resCommon = {
    onFulfilled(response, options) {
        const {i18n, message} = options
        if (response.status === 403) {
            message.error(i18n.global.t('error.403'))
        } else if (response.status === 401) {
            message.error(i18n.global.t('error.401'))
        }
        return response
    },
    onRejected(error, options) {
        const {i18n, message} = options
        const {response} = error
        if (response) {
            message.destroy()
            if (response && response.status === 403) {
                message.error(i18n.global.t('error.403'))
            } else if (response.status === 401) {
                message.error(i18n.global.t('error.401'))
            } else {
                message.error(response.status + '.' + response.statusText)
            }
        } else {
            message.error(error.message)
        }

        return Promise.reject(error)
    }
}

const reqCommon = {
    /**
     * 发送请求之前做些什么
     * @param config axios config
     * @param options 应用配置 包含: {router, i18n, store, message}
     * @returns {*}
     */
    onFulfilled(config, options) {
        const {router} = options
        const {url, xsrfCookieName, cancelSource} = config
        if (xsrfCookieName && !Cookie.get(xsrfCookieName)) {
            if (!(url === ACCOUNT_LOGIN ||
                url === ACCOUNT_SENDVERICODE ||
                url === ACCOUNT_RESETPWD ||
                url === ACCOUNT_EXISTLOGINNAME ||
                url === ACCOUNT_SENDVERICODEFORREG ||
                url === ACCOUNT_REGISTER ||
                url === HOTEL_TOP_HOTELS
            )) {
                cancelSource.cancel('认证 token 已过期，将重新登录')
                router.push({name: 'home'})
            }
        }
        return config
    },
    /**
     * 请求出错时做点什么
     * @param error 错误对象
     * @param options 应用配置 包含: {router, i18n, store, message}
     * @returns {Promise<never>}
     */
    onRejected(error, options) {
        const {message} = options
        message.error(error.message)
        return Promise.reject(error)
    }
}

export default {
    request: [reqCommon], // 请求拦截
    response: [resCommon] // 响应拦截
}
