import {ACCOUNT_LOGIN, ACCOUNT_GETCURRENTUSER, ACCOUNT_SENDVERICODE,ACCOUNT_SENDVERICODEFORREG,ACCOUNT_RESETPWD,
    ACCOUNT_EXISTLOGINNAME,ACCOUNT_REGISTER,ACCOUNT_UPDATE_INFO,ACCOUNT_UPDATE_PWD,ACCOUNT_NOTICE_LIST,ACCOUNT_NOTICE_READ,ACCOUNT_NOTICE_DELETE,
    ACCOUNT_NOTICE_READALL
} from '@/services/api'
import {request, METHOD} from '@/utils/request'

export async function login(name, password, remember) {
    return request(ACCOUNT_LOGIN, METHOD.POST, {
        LoginName: name,
        Password: password,
        AutoLogin: remember
    })
}

export async function getCurrentUser() {
    return request(ACCOUNT_GETCURRENTUSER, METHOD.GET)
}

export async function sendVeriCode(userEmail, language) {
    return request(ACCOUNT_SENDVERICODE, METHOD.GET, {
        UserEmail: userEmail,
        Language: language
    })
}

export async function resetPassword(userEmail, veriCode, password) {
    return request(ACCOUNT_RESETPWD, METHOD.POST, {
        UserEmail: userEmail,
        VeriCode: veriCode,
        Password: password
    })
}

export async function existLoginName(loginName) {
    return request(ACCOUNT_EXISTLOGINNAME, METHOD.GET, {
        LoginName: loginName
    })
}

export async function sendVeriCodeForReg(userEmail, language) {
    return request(ACCOUNT_SENDVERICODEFORREG, METHOD.GET, {
        UserEmail: userEmail,
        Language: language
    })
}

export async function register(postData) {
    return request(ACCOUNT_REGISTER, METHOD.POST, postData)
}

export async function updateInfo(params) {
    return request(ACCOUNT_UPDATE_INFO, METHOD.POST, params)
}

export async function updatePwd(params) {
    return request(ACCOUNT_UPDATE_PWD, METHOD.POST, params)
}

export async function getNoticeList(params) {
    return request(ACCOUNT_NOTICE_LIST, METHOD.POST, params)
}

export async function readNotice(ids) {
    return request(ACCOUNT_NOTICE_READ, METHOD.POST, { noticeIds: ids })
}

export async function deleteNotice(ids) {
    return request(ACCOUNT_NOTICE_DELETE, METHOD.POST, { noticeIds: ids })
}

export async function readAllNotice() {
    return request(ACCOUNT_NOTICE_READALL, METHOD.GET)
}