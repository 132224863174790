<template>
  <a-config-provider :locale="language === 'zh_CN' ? zhCN : enUS">
    <a-layout :style="{ minWidth: fullWidth + 'px'}">
      <a-layout-header v-if="showHeaderFooter">
        <div>
          <a-menu v-model:selectedKeys="selectedMenuKeys" @click="goTo" mode="horizontal" :style="{ textAlign: 'center' }"
                  v-if="loginUser == null">
            <a-menu-item key="home">{{ $t('home') }}</a-menu-item>
            <a-menu-item key="#about"><a href="/home#about">{{ $t('about_us') }}</a></a-menu-item>
            <a-menu-item key="#partner"><a href="/home#partner">{{ $t('partners') }}</a></a-menu-item>
          </a-menu>
          <a-menu v-model:selectedKeys="selectedMenuKeys" @click="goTo" mode="horizontal" :style="{ textAlign: 'center' }"
                  v-if="loginUser">
            <a-menu-item key="home">{{ $t('home') }}</a-menu-item>
            <a-menu-item key="hotel_index">{{ $t('hotel_index') }}</a-menu-item>
            <a-sub-menu>
              <template #title>{{ $t('booking_list') }}</template>
              <a-menu-item key="booking_list">{{ $t('booking_list') }}</a-menu-item>
              <a-menu-item key="ticket_list">{{ $t('ticket_list') }}</a-menu-item>
            </a-sub-menu>
          </a-menu>
        </div>
        <div :style="{ position: 'relative', height: '100%', float: 'left', top: '-80px' }">
          <a-image :src="'/images/logo_' + language + '.png'" :preview="false" :width="161" :height="40" :style="{ padding: '16px 0 24px 0'}"/>
        </div>
        <div :style="{ position: 'relative', top: '-80px', float: 'right',marginRight:'10px' }">
          <a-space align="center">
            <a-select ref="select" v-model:value="language" :bordered="false" :dropdownMatchSelectWidth="false">
              <a-select-option value="zh_CN">
                <a-space align="center" :size="8">
                  <svg-zh-cn class="lang_svg" />
                  <span>中文</span>
                </a-space>
              </a-select-option>
              <a-select-option value="en_US">
                <a-space align="center" :size="8">
                  <svg-en-us class="lang_svg" />
                  <span>English</span>
                </a-space>
              </a-select-option>
            </a-select>
            <a-divider type="vertical" :style="{ height: '20px'}" v-if="loginUser"/>
            <a-select ref="select" v-model:value="currency" :bordered="false" :options="currencyOptions"
                      :dropdownMatchSelectWidth="false" v-if="loginUser">
              <template #option="{ value: val }">
                {{ val + ' ' + $t('currency.' + val) }}
              </template>
            </a-select>
            <a-divider type="vertical" :style="{ height: '20px' }"/>
            <a-popover placement="bottomRight" trigger="click" v-if="loginUser == null">
              <template #content>
                <a-form name="login_form" class="login-form" :model="loginForm" @submit="onLogin">
                  <a-form-item name="username" :rules="[{ required: true, message: $t('input_username') }]">
                    <a-input :placeholder="$t('username')" v-model:value="loginForm.username">
                      <template #prefix>
                        <UserOutlined/>
                      </template>
                    </a-input>
                  </a-form-item>

                  <a-form-item name="password" :rules="[{ required: true, message: $t('input_password') }]">
                    <a-input-password :placeholder="$t('password')" v-model:value="loginForm.password">
                      <template #prefix>
                        <LockOutlined/>
                      </template>
                    </a-input-password>
                  </a-form-item>

                  <a-alert type="error" :closable="false" v-if="loginForm.error" :message="loginForm.error" showIcon/>

                  <div class="login-form-wrap">
                    <a-form-item name="remember" no-style>
                      <a-checkbox v-model:checked="loginForm.remember">{{ $t('remember_me') }}</a-checkbox>
                    </a-form-item>
                    <router-link :to="{name: 'find-password'}">{{ $t('forgot_password') }}</router-link>
                  </div>

                  <a-form-item style="margin-bottom: 0;">
                    <a-button type="primary" html-type="submit" block :loading="loginLoading"
                              :disabled="loginForm.username === '' || loginForm.password === ''">
                      {{ $t('login') }}
                    </a-button>
                    <router-link :to="{name: 'register'}">{{ $t('no_account_register') }}</router-link>
                  </a-form-item>
                </a-form>
              </template>
              <a-typography-link>
                {{ $t('login') }}/{{ $t('register') }}
              </a-typography-link>
            </a-popover>
            <a-popover placement="bottomRight" trigger="click" v-if="loginUser" :overlayStyle="{height:'400px'}">
              <template #content>
                <a-space direction="vertical">
                  <a-typography-text :style="{ color: '#666666'}">
                    <IdcardOutlined :style="{fontSize: '18px'}"/> &nbsp;{{ $t('org_info')}} {{ loginUser.channel.toUpperCase() }}
                  </a-typography-text>
                  <a-typography-text>{{ loginUser.company_name }}</a-typography-text>
                  <a-divider :style="{ margin: '0 0'}"/>
                  <a-typography-text :style="{ color: '#666666'}">
                    <AccountBookOutlined :style="{fontSize: '18px'}"/> &nbsp;{{ $t('credit_balance') }}
                  </a-typography-text>
                  <div>
                    <a-typography-text :style="{color:'#FF5C00',fontWeight:'600'}">
                      {{ loginUser.credit_currency }}
                      {{ $filters.formatAmount(loginUser.credit_balance, 2) }}
                    </a-typography-text>
                    <router-link :to="{name: 'account_credit_balance'}" style="float: right;">{{ $t('view_details') }}</router-link>
                  </div>
                  <a-divider :style="{ margin: '0 0'}"/>
                  <a-space align="center" style="margin-bottom: 20px">
                    <a-badge :count="loginUser.UnreadMesNumber" :offset="[-20,5]">
                      <a-button type="link" block @click="this.$router.push({name: 'account_message'})">
                        <AlertOutlined style="font-size: 16px"/><div>{{ $t('messages') }}</div>
                      </a-button>
                    </a-badge>
                    <a-button type="link" block @click="this.$router.push({name: 'account_setting'})">
                      <SettingOutlined style="font-size: 16px"/><div>{{ $t('settings') }}</div>
                    </a-button>
                    <a-button type="link" block @click="onLogout">
                      <LogoutOutlined style="font-size: 16px"/><div>{{ $t('logout') }}</div>
                    </a-button>
                  </a-space>
                </a-space>
              </template>
              <a-typography-link>
                <a-badge :count="loginUser.UnreadMesNumber" size="small" :offset="[5,-8]">
                  {{ loginUser.login_name }}
                </a-badge>
              </a-typography-link>
            </a-popover>
          </a-space>
        </div>
      </a-layout-header>
      <a-layout-content>
        <router-view/>
      </a-layout-content>
      <a-layout-footer class="footer" v-if="showHeaderFooter">
        <a-row class="footer-link">
          <a-col :span="8">
            <a-space direction="vertical" align="start">
              <h3>
                <a href="/home#about" class="white">{{ $t('about_us') }}</a>
              </h3>
              <router-link to="/home/booking-rules" class="white">{{ $t('booking_rules') }}</router-link>
              <router-link to="/home/faq" class="white">{{ $t('faq') }}</router-link>
              <router-link to="/home/privacy-policy" class="white">{{ $t('privacy_policy') }}</router-link>
              <router-link to="/home/terms-service" class="white">{{ $t('service_terms') }}</router-link>
            </a-space>
          </a-col>
          <a-col :span="8">
            <a-space direction="vertical" align="start">
              <h3 class="white">{{ $t('contact_us') }}</h3>
              <span><phone-outlined :style="{ color: '#fff'}"/> +86-411-82820640</span>
              <span><mail-outlined :style="{ color: '#fff'}"/> {{ $t('cooperation') }}: <a href="mailto:info@jidutravel.com">info@jidutravel.com</a></span>
              <span><mail-outlined :style="{ color: '#fff'}"/> {{ $t('customer_service') }}: <a href="mailto:cs@jidutravel.com">cs@jidutravel.com</a></span>
            </a-space>
          </a-col>
          <a-col :span="8">
            <a-space direction="vertical" align="start">
              <h3 class="white">{{$t('wechat_official_account')}}</h3>
              <a-image :width="100" :preview="false" src="/images/qrcode.png"/>
            </a-space>
          </a-col>
        </a-row>
        <a-row class="footer-copyright">
          <a-col :span="24" class="footer-copyright-text">©2012 - {{ new Date().getFullYear() }} 大连几度国际旅行社有限公司 | 辽ICP备14006724号-2
          </a-col>
        </a-row>
      </a-layout-footer>
    </a-layout>
  </a-config-provider>
  <a-back-top/>
</template>

<script>
import {computed, defineComponent, ref, watch, reactive, provide} from 'vue';
import {
  PhoneOutlined,
  MailOutlined,
  UserOutlined,
  LockOutlined,
  IdcardOutlined,
  LogoutOutlined,
  AlertOutlined,
  SettingOutlined,
  AccountBookOutlined
} from '@ant-design/icons-vue';
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import i18n from './locales'
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import enUS from 'ant-design-vue/es/locale/en_US';
import SvgZhCn from '@/assets/icon/zh_CN.svg';
import SvgEnUs from '@/assets/icon/en_US.svg';
import {getCurrentUser, login} from '@/services/account'
import {setAuthorization, removeAuthorization, checkAuthorization} from '@/utils/request'
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import {isEmpty} from "@/utils/util";

export default defineComponent({
  components: {
    PhoneOutlined,
    MailOutlined,
    UserOutlined,
    LockOutlined,
    IdcardOutlined,
    LogoutOutlined,
    AlertOutlined,
    SettingOutlined,
    AccountBookOutlined,SvgZhCn,SvgEnUs
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    i18n.global.locale = store.getters["setting/getLanguage"]
    const language = ref(i18n.global.locale);
    watch(language, val => {
      store.commit("setting/setLanguage", val)
      location.reload()
    });
    dayjs.locale(language.value.replace('_','-'))

    const currency = ref(store.getters["setting/getCurrency"]);
    watch(currency, val => {
      store.commit("setting/setCurrency", val)
    });

    const currencyOptions = ref([
      {value: 'CNY'},
      {value: 'USD'},
      {value: 'JPY'},
    ])

    const loginUser = computed(() => {
      return store.getters["account/getUser"]
    })

    const loginForm = reactive({
      username: '',
      password: '',
      remember: true,
      error: false
    })

    const loginLoading = ref(false)

    const selectedMenuKeys = ref(['home'])
    provide('selectedMenuKeys', selectedMenuKeys)
    const goTo = (e) => {
      if (isEmpty(e.key) || e.key.indexOf('#') === 0)
        return

      router.push({name: e.key})
    }

    const onLogin = () => {
      loginLoading.value = true
      login(loginForm.username, loginForm.password, loginForm.remember).then(res => {
        const loginRes = res.data
        if (loginRes.Success) {
          setAuthorization({
            token: loginRes.Data.Token,
            expireAt: new Date(new Date().getTime() + loginRes.Data.ExpireAt * 60 * 1000)
          })
          store.commit("account/setUser", loginRes.Data.User)
          currency.value = loginRes.Data.User.credit_currency
          loginForm.error = false

          selectedMenuKeys.value = ['hotel/index']
          router.push({name: 'hotel_index'})
        } else {
          loginForm.error = i18n.global.t('error.' + loginRes.Code)
        }
        loginLoading.value = false
      }).catch(error => {
        console.log(error)
        loginLoading.value = false
      })
    }

    const onLogout = () => {
      store.commit("account/setUser", null)
      removeAuthorization()

      selectedMenuKeys.value = ['home']
      router.push({ name: 'home', replace: true })
    }

    const onRefreshUser = () => {
      getCurrentUser().then(res => {
        const resData = res.data
        if (resData.Success) {
          store.commit("account/setUser", resData.Data)
        } else {
          console.log(i18n.global.t('error.' + resData.Code))
        }
      }).catch(error => {
        console.log(error)
      })
    }

    if (checkAuthorization())
      onRefreshUser()
    else {
      store.commit("account/setUser", null)
      removeAuthorization()
    }

    const showHeaderFooter = ref(true)
    provide('showHeaderFooter', showHeaderFooter)

    return {
      selectedMenuKeys,
      showHeaderFooter,
      language,
      currency,
      currencyOptions,
      enUS,
      zhCN,
      loginUser,
      loginLoading,
      loginForm,
      goTo,
      onLogin,
      onLogout,
      fullWidth: window.screen.availWidth - 30
    }
  },
});
</script>
<style scoped>
.login-form {
  width: 300px;
}

.login-form-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.lang_svg {
  width: 25px;
  margin-bottom: -3px;
}
</style>