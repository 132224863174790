/**
 * 格式化金额
 * @param value 金额
 * @param decimals 小数位数
 * @returns {string}
 */
function formatAmount(value, decimals) {
  if(value === undefined)
    value = 0.00

  let digitsRE = /(\d{3})(?=\d)/g
  value = parseFloat(value)
  if (!isFinite(value) || (!value && value !== 0)) return ''
  decimals = decimals != null ? decimals : 2
  let stringified = Math.abs(value).toFixed(decimals)
  let _int = decimals
      ? stringified.slice(0, -1 - decimals)
      : stringified
  let i = _int.length % 3
  let head = i > 0
      ? (_int.slice(0, i) + (_int.length > 3 ? ',' : ''))
      : ''
  let _float = decimals
      ? stringified.slice(-1 - decimals)
      : ''
  let sign = value < 0 ? '-' : ''
  return sign + head +
      _int.slice(i).replace(digitsRE, '$1,') +
      _float
}

module.exports = { formatAmount }
