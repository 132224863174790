export default {
    namespaced: true,
    state: {
        user: undefined,
    },
    getters: {
        getUser: state => {
            if(!state.user) {
                const user = localStorage.getItem(process.env.VUE_APP_USER_KEY)
                if (user !== null) {
                    try {
                        state.user = JSON.parse(user)
                    } catch (e) {
                        console.error(e)
                    }
                }
            }
            return state.user
        },
    },
    mutations: {
        setUser (state, user) {
            state.user = user
            if(user === undefined || user === null)
                localStorage.removeItem(process.env.VUE_APP_USER_KEY)
            else
                localStorage.setItem(process.env.VUE_APP_USER_KEY, JSON.stringify(user))
        },
    }
}