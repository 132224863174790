import { createApp } from 'vue'
import { router,loadGuards} from './router'
import store from './store'
import Plugins from './plugins'
import i18n from './locales'
import App from './App.vue'
import Antd from 'ant-design-vue'
import {message} from 'ant-design-vue'
import './assets/css/theme.less'
import {formatAmount} from './utils/formatter'

import {loadInterceptors} from './utils/request'
import interceptors from './utils/axios-interceptors'

const app = createApp(App, Antd)
app.use(i18n).use(store).use(router).use(Antd).use(Plugins)

app.config.globalProperties.$message = message
app.config.globalProperties.$filters = { formatAmount }

// 加载 axios 拦截器
loadInterceptors(interceptors, {router, store, i18n, message})
loadGuards({router, store, i18n, message})

app.mount('#app')