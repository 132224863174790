export default {
    namespaced: true,
    state: {
        language: "zh_CN",
        currency: "CNY",
    },
    getters: {
        getLanguage: state => {
            const language = localStorage.getItem(process.env.VUE_APP_USER_LANGUAGE)
            if(language !== null)
                state.language = language

            return state.language
        },
        getCurrency: state => {
            const currency = localStorage.getItem(process.env.VUE_APP_USER_CURRENCY)
            if(currency !== null)
                state.currency = currency

            return state.currency
        },
    },
    mutations: {
        setLanguage (state, language) {
            state.language = language
            localStorage.setItem(process.env.VUE_APP_USER_LANGUAGE, language)
        },
        setCurrency (state, currency) {
            state.currency = currency
            localStorage.setItem(process.env.VUE_APP_USER_CURRENCY, currency)
        },
    }
}